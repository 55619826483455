import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import '@fortawesome/fontawesome-free/css/all.min.css'

import "datatables.net-bs5"
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css"
import "jszip"
import "pdfmake"
import "datatables.net-buttons-bs5/js/buttons.bootstrap5"
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css"
import "datatables.net-buttons/js/buttons.colVis"
import "datatables.net-buttons/js/buttons.flash"
import "datatables.net-buttons/js/buttons.html5"
import "datatables.net-buttons/js/buttons.print"
import '../src/assets/css/style.css'
  import 'bootstrap/dist/css/bootstrap.min.css'
  import 'bootstrap/dist/js/bootstrap.min.js'
  import '../src/assets/css/desktop.css'

  import Vue3Lottie from 'vue3-lottie'
  import 'vue3-lottie/dist/style.css'
  
  import '../public/dist/css/adminlte.css'
  import '../public/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css'
  // import '../node_modules/adminjs/dist/js/adminlte'
  // import '../node_modules/adminjs/dist/js/demo'
  // import '../node_modules/adminjs/dist/js/pages/dashboard'

  import VueObserveVisibility from 'vue-observe-visibility';


  
 
createApp(App).use(store).use(router).use(Toast).use(VueObserveVisibility).use(Vue3Lottie).mount('#app')

