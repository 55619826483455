
<template>
  <router-view/>
</template>


<style lang="scss">

</style>

  


